import React from 'react';
import AOS from 'aos';
// import emailjs from 'emailjs-com';
import { Box, IconButton } from '@mui/material';

import '../assets/styles/contact.scss';

AOS.init();

const contact = () => {

  var course = "./img/logos-social-networks/course.png";
  var youtube = "./img/logos-social-networks/youtube.png";
  var linkedin = "./img/logos-social-networks/linkedin.png";
  var domestika = "./img/logos-social-networks/domestika.png";
  var instagram = "./img/logos-social-networks/instagram.png";
  var artstation = "./img/logos-social-networks/artstation.png";

  // function sendEmail(e) {
  //   const success = document.getElementById("success");
  //   const button = document.getElementById("send_message");
  //   const failed = document.getElementById("failed");
  //   e.preventDefault();
  //
  //   emailjs.sendForm('gmail', 'template_csfdEZiA', e.target, 'user_zu7p2b3lDibMCDutH5hif')
  //   .then((result) => {
  //     console.log(result.text);
  //     success.classList.add('show');
  //     button.classList.add('show');
  //     failed.classList.remove('show');
  //   }, (error) => {
  //     console.log(error.text);
  //     failed.classList.add('show');
  //   });
  // }

  return(
    <Box className="section">
      <div className="container">
        <div className="row cnct-title">
          <div className="col-md-12 text-center">
            <h4>You can see more of my work here</h4>
          </div>
        </div>
        <div className='cnct-buttons'>
          <div className='cnct-button'>
            <IconButton onClick={() =>(window.open('https://www.instagram.com/samanthaa.ma', "_blank"))}>
              <img src={instagram} alt='social-img'/>
            </IconButton>
            <p>Instagram</p>
          </div>

          <div className='cnct-button'>
            <IconButton onClick={() =>(window.open('https://www.artstation.com/martinezsamantha', "_blank"))}>
              <img src={artstation} alt='social-img'/>
            </IconButton>
            <p>ArtStation</p>
          </div>

          <div className='cnct-button'>
            <IconButton onClick={() =>(window.open('http://www.youtube.com/@samsamillustration2991', "_blank"))}>
              <img src={youtube} alt='social-img'/>
            </IconButton>
            <p>Youtube</p>
          </div>

          <div className='cnct-button'>
            <IconButton onClick={() =>(window.open('https://www.linkedin.com/in/samantha-martínez-54a603263', "_blank"))}>
              <img src={linkedin} alt='social-img'/>
            </IconButton>
            <p>LinkedIn</p>
          </div>

          <div className='cnct-button'>
            <IconButton onClick={() =>(window.open('https://www.domestika.org/es/courses/4576-ilustracion-de-personajes-fantasticos-de-estilo-cartoon/samanthamartinez', "_blank"))}>
              <img src={domestika} alt='social-img'/>
            </IconButton>
            <p>Domestika</p>
          </div>

        </div>
        <div className="row cnct-email">
          <h4>Do you need illustrations for your project?</h4>
          <h4>Send me an email and let's work together!</h4>
          <h6>samanthamartinezillustration@gmail.com</h6>
        </div>
        {/*
        <div className="row cnct-resume">
          <Button> <p> Check out my resume! </p> </Button>
        </div>
        */}
        {/*
        <div className="col-lg-8 offset-lg-2">
          <div className="contact_form_wrapper">
            <form name="contactForm" id="contact_form" className="form-border" onSubmit={sendEmail}>
              <div className="row">
                <div className="top-inputs col-md-6">
                  <TextField className='text-input' label="Name" variant="outlined" />
                </div>
                <div className="top-inputs col-md-6">
                  <TextField className='text-input' label="email" variant="outlined" />
                </div>
              </div>
              <div className="text-center">
                <TextField className='text-input' rows={6} label="Message" variant="outlined" multiline />
                <div id='success' className='hide'>Your message has been sent!</div>
                <div id='failed' className='hide'>Message failed to be sent...</div>
                <div id='submit' className="mt30">
                  <button type='submit' id='send_message' className="btn-main"> Send Message </button>
                </div>
              </div>
            </form>
          </div>
        </div> */}
        <div className='course-img'>
          <img src={course} alt='Checkout my course' />
          <div className='spacer'></div>
        </div>
      </div>
    </Box>
  );
}

export default contact;
