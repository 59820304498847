import About from '../section/about';
// import Hero from '../section/hero';
import Footer from '../section/footer';
import Preloader from "../layout/Preloader";
import HeaderBanner from '../section/headerBanner';

import '../assets/styles/about.scss';
import { createGlobalStyle } from 'styled-components';
const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit{
      display: block ;
    }
    .navbar-brand .imgsaly{
      display: none !important;
    }
`;

export default function AboutMe() {

  return (
    <div>
      <GlobalStyles/>
      <Preloader/>
      <div className="home">

        <HeaderBanner />

        {/* ABOUT */}
        <section id="about" className="">
          <About/>
        </section>

        <section id="Footer" >
          <Footer />
        </section>
      </div>
    </div>
  );
}
