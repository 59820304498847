import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import Home from "../src/pages/home";
import About from "../src/pages/about";
import Books from "../src/pages/books";
import Contact from "../src/pages/contact";
import Concept from "../src/pages/concept";
import Portfolio from "../src/pages/portfolio";
import Illustration from "../src/pages/illustration";

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path = "*" element={<Navigate to='/Illustrations' replace />} />
          <Route path = "/Illustrations" element={<Illustration />}/>
          <Route path = "/About" element={<About />}/>
          <Route path = "/Books" element={<Books />}/>
          <Route path = "/Contact" element={<Contact />}/>
          <Route path = "/Concept" element={<Concept />}/>
          <Route path = "/Portfolio" element={<Portfolio />}/>

          </Routes>
      </BrowserRouter>
    </div>
  );
}
