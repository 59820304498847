import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Modal, ImageList, ImageListItem, IconButton } from '@mui/material';
// import { Masonry } from '@mui/lab';

import { createGlobalStyle } from 'styled-components';
import "../assets/styles/book-gallery.scss";

import GalleryClose from '../assets/img/buttons/gallery-close.png'

AOS.init();
const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

export default function Gallery() {

  // const bookInfo = [
  //   {
  //     key: 0,
  //     title: "Una Niña Gigante Sobre Ruedas",
  //     author: "Soffi P. Guido",
  //     publisher: "Penguin Random House",
  //     year: "2024",
  //     desc: "Written by Soffi P. Guido, Publisher Penguin Random House, 2024",
  //     cover: "./img/books/una-nina-gigante-sobre-ruedas/nina-gigante_00.png",
  //     bookIllus: [
  //       {
  //         key: 0,
  //         illus: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_01.jpg",
  //       },
  //       {
  //         key: 1,
  //         illus: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_02.jpg",
  //
  //       },
  //       {
  //         key: 2,
  //         illus: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_03.jpg"
  //       }
  //     ],
  //   },
  //   {
  //     key: 1,
  //     title: "Aviador de Cometas",
  //     author: "Based on a story from the podcast Cuentos increíbles, Sonoro Global Media Corp",
  //     publisher: "Global Media Corp",
  //     year: "2023",
  //     desc: "Based on a story from the podcast Cuentos increíbles, Sonoro Global Media Corp. 2023",
  //     cover: "./img/books/aviador-de-cometas/aviadorDeCometas_00.jpg",
  //     bookIllus: [
  //       {
  //         key: 0,
  //         illus: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_01.jpg",
  //       },
  //       {
  //         key: 1,
  //         illus: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_02.jpg",
  //
  //       },
  //       {
  //         key: 2,
  //         illus: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_03.jpg"
  //       }
  //     ],
  //   },
  //   {
  //     key: 2,
  //     title: "Club de las Niñas Fantasma",
  //     author: "Raquel Castro & Alberto Chimal",
  //     publisher: "Penguin Random House",
  //     year: "2021",
  //     desc: "Written by Raquel Castro and Alberto Chimal \n Published by Penguin Random Hosue, 2021",
  //     sinopsis: "¿Habrá algo peor que morirse? ¿Qué tal vagar como fantasma en el último lugar donde estuviese con vida, por toda la eternidad, sin posibilidad de salir de ahí? Ése parece ser el triste destino de Isabel, una niña que tiene más de sesenta años atrapada en un edificio de oficinas, y de la que todo mundo huye. Ella ya está resignada a ser la típica 'niña que aparece en las noches' (aunque en realidad la pueden ver a cualquier hora),  hasta que un día se topa con Carme y René, dos amigos (aunque no lo parecen) aficionados a las historias de terror. Y para mayor sorpresa, los tres descubrirán que hay otro edificio cerca de ahí, con otras dos niñas fantasma atrapadas. ¿Será coincdencia o habrá algo más adetrás de ello? ¿Porqué sólo hay niñas fantasma? ¿Existirá alguna manera de ayudarlas para que dejen de ser almas en pena? ¿Tal vez presentar un oficio por triplicado? ¿Pero a quién?",
  //     cover: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_00.png",
  //     bookIllus: [
  //       {
  //         key: 0,
  //         illus: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_01.jpg",
  //       },
  //       {
  //         key: 1,
  //         illus: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_02.jpg",
  //
  //       },
  //       {
  //         key: 2,
  //         illus: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_03.jpg"
  //       }
  //     ],
  //   }
  // ];

  const bookInfo = [
    {
      key: 0,
      title: "Una Niña Gigante Sobre Ruedas",
      author: "Written by Soffi P. Guido",
      publisher: "Penguin Random House",
      year: ", 2024",
      desc: "Written by Soffi P. Guido, Publisher Penguin Random House, 2024",
      cover: "./img/books/una-nina-gigante-sobre-ruedas/nina-gigante_00.jpg",
      bookIllus: [
        {
          key: 0,
          illus: "./img/books/una-nina-gigante-sobre-ruedas/nina-gigante_01.jpg",
        },
        {
          key: 1,
          illus: "./img/books/una-nina-gigante-sobre-ruedas/nina-gigante_02.jpg",

        },
        {
          key: 2,
          illus: "./img/books/una-nina-gigante-sobre-ruedas/nina-gigante_03.jpg"
        }
      ],
    },
    {
      key: 1,
      title: "Aviador de Cometas",
      author: "Based on a story from the podcast Cuentos increíbles",
      publisher: "Global Media Corp",
      year: ", 2023",
      desc: "Based on a story from the podcast Cuentos increíbles, Sonoro Global Media Corp. 2023",
      cover: "./img/books/aviador-de-cometas/aviadorDeCometas_00.jpg",
      bookIllus: [
        {
          key: 0,
          illus: "./img/books/aviador-de-cometas/aviadorDeCometas_01.jpg",
        },
        {
          key: 1,
          illus: "./img/books/aviador-de-cometas/aviadorDeCometas_02.jpg"

        },
        {
          key: 2,
          illus: "./img/books/aviador-de-cometas/aviadorDeCometas_03.jpg"
        }
      ],
      titleVisible: true,
      publisherVisible: false,

    },
    {
      key: 2,
      title: "El Club de las Niñas Fantasma",
      author: "Written by Raquel Castro & Alberto Chimal",
      publisher: "Penguin Random House",
      year: ", 2021",
      desc: "Written by Raquel Castro and Alberto Chimal \n Published by Penguin Random House, 2021",
      sinopsis: "¿Habrá algo peor que morirse? ¿Qué tal vagar como fantasma en el último lugar donde estuviese con vida, por toda la eternidad, sin posibilidad de salir de ahí? Ése parece ser el triste destino de Isabel, una niña que tiene más de sesenta años atrapada en un edificio de oficinas, y de la que todo mundo huye. Ella ya está resignada a ser la típica 'niña que aparece en las noches' (aunque en realidad la pueden ver a cualquier hora),  hasta que un día se topa con Carme y René, dos amigos (aunque no lo parecen) aficionados a las historias de terror. Y para mayor sorpresa, los tres descubrirán que hay otro edificio cerca de ahí, con otras dos niñas fantasma atrapadas. ¿Será coincdencia o habrá algo más adetrás de ello? ¿Porqué sólo hay niñas fantasma? ¿Existirá alguna manera de ayudarlas para que dejen de ser almas en pena? ¿Tal vez presentar un oficio por triplicado? ¿Pero a quién?",
      cover: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_00.jpg",
      bookIllus: [
        {
          key: 0,
          illus: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_01.jpg",
        },
        {
          key: 1,
          illus: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_02.jpg",

        },
        {
          key: 2,
          illus: "./img/books/el-club-de-las-ninas-fantasma/club-fantasma_03.jpg"
        }
      ],
    }
  ];

  const pageSizeLg = useMediaQuery('(min-width:1280px)');
  const pageSizeMd = useMediaQuery('(min-width:900px)');
  const pageSizeXs = useMediaQuery('(min-width:575px)');
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const openModal = (index) => {
    setBookIndex(index);
    setOpen(true);
  }
  const [bookIndex, setBookIndex] = useState(0);

  useEffect(() => {
    function handleContextMenu(e){
      e.preventDefault();
    }
    const rootElement = document.getElementById('book-img');
    rootElement.addEventListener('contextmenu', handleContextMenu);

    return() => {
      rootElement.addEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return(
    <div id="gallery" className="book-gallery">
      <GlobalStyles/>

      <ImageList id='book-img' className="img-list" cols={pageSizeMd? 3:2} gap={pageSizeXs? 0:25}>
      { bookInfo.map(book => (
          <ImageListItem id='book' key={book.key} className="book-item col-lg-10 col-md-10 col-sm-10">
            <div className="book-cover">
              <img src={book.cover} alt="Book Cover" loading="lazy" onClick={() => openModal(book.key)}/>
            </div>
            <div className="book-info">
              <h3 className="book-title"> {book.title} </h3>
              <h4 className="book-desc"> {book.author} </h4>
              <h4 className="book-desc"> {book.publisher + book.year} </h4>
            </div>
          </ImageListItem>
      ))}
      </ImageList>

      <Modal className="book-modal" open={open} onClose={closeModal}>
        <Box className="book-container">
          <div className="book-info">
            <h3 className="book-title"> {bookInfo[bookIndex].title} </h3>
            <h5 className="book-desc"> {bookInfo[bookIndex].author} </h5>
            <h5 className="book-desc"> {bookInfo[bookIndex].publisher + bookInfo[bookIndex].year} </h5>

            {/*<p> {bookInfo[bookIndex].sinopsis} </p>*/}
          </div>
          <div className="book-image-list-container">
            <ImageList className="book-image-list" cols={pageSizeLg? 2:1}>
              <ImageListItem className="book-list-item">
                <img src={bookInfo[bookIndex].cover} alt="Book Cover" />
              </ImageListItem>
              {bookInfo[bookIndex].bookIllus != null && bookInfo[bookIndex].bookIllus.map(illus => (
                <ImageListItem className="book-list-item" key={illus.key}>
                  <img src={illus.illus} alt="Book Illustration" />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
          <IconButton
            id="close-btn"
            className="icon-button-close"
            onClick={closeModal}
            >
            <img src={GalleryClose} alt='close' />
          </IconButton>
        </Box>
      </Modal>
    </div>
  );
}
