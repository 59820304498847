import Contact from '../section/contact';
import Footer from '../section/footer';
import Preloader from "../layout/Preloader";
import HeaderBanner from '../section/headerBanner';

import '../assets/styles/contact.scss';
import { createGlobalStyle } from 'styled-components';
const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit{
      display: block ;
    }
    .navbar-brand .imgsaly{
      display: none !important;
    }
`;

export default function ContactMe() {

  return (
    <div>
    <GlobalStyles/>
    <Preloader/>
      <div className="home">

        <HeaderBanner />

        {/* contact */}
        <section id="contact" >
          <Contact/>
        </section>

        <section id="Footer" >
          <Footer />
        </section>
      </div>
    </div>
  );
}
