import React from 'react';
import { IconButton, Icon } from '@mui/material';
// import InstaIcon from '@mui/icons-material/Instagram';
// import YoutubeIcon from '@mui/icons-material/YouTube';
// import LinkedIcon from '@mui/icons-material/LinkedIn';
import ArrowRight from '@mui/icons-material/East';
import Copyright from '@mui/icons-material/Copyright';

import "../assets/styles/footer.scss";

const footer = () => {

  var monoYoutube = "./img/logos-social-networks/monoYoutube.png";
  var monoLinkedin = "./img/logos-social-networks/monoLinkedin.png";
  var monoDomestika = "./img/logos-social-networks/monoDomestika.png";
  var monoInstagram = "./img/logos-social-networks/monoInstagram.png";
  var monoArtstation = "./img/logos-social-networks/monoArtstation.png";

  return(
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-6 copy">
            <a href="https://www.tredok.com" target="_blank" rel="noreferrer">
              <span className="copy"> <Copyright /> Copyright 2023 - SamSam </span>
            </a>
          </div>
          <div className="col-md-6">
            <div className="social-icons">

              <div className="cutie">
                 Cutie here <Icon> <ArrowRight /> </Icon>
              </div>
              <a target='blank' href="https://www.instagram.com/samanthaa.ma/">
                <IconButton> <img src={monoInstagram} alt="instagram logo" height="24" width="24"/> </IconButton>
              </a>
              <a target='blank' href="https://www.artstation.com/martinezsamantha">
                <IconButton> <img src={monoArtstation} alt="artstation logo" height="24" width="24"/> </IconButton>
              </a>
              <a target='blank' href="http://www.youtube.com/@samsamillustration2991">
                <IconButton> <img src={monoYoutube} alt="youtube logo" height="24" width="24"/> </IconButton>
              </a>
              <a target='blank' href="https://www.domestika.org/es/courses/4576-ilustracion-de-personajes-fantasticos-de-estilo-cartoon/samanthamartinez">
                <IconButton> <img src={monoDomestika} alt="domestika logo" height="24" width="24"/> </IconButton>
              </a>
              <a target='blank' href="https://www.linkedin.com/in/samantha-martínez-54a603263">
                <IconButton> <img src={monoLinkedin} alt="linkedin logo" height="24" width="24"/> </IconButton>
              </a>
              {/*
              <a target='blank' href="https://martinezsamantha.gumroad.com/">
                <IconButton> <img src={svgGumroad} alt="gumroad logo" height="24" width="24"/> </IconButton>
              </a>
              <a target='blank' href="https://www.instagram.com/samanthaa.ma/">
                <IconButton> <InstaIcon /> </IconButton>
              </a>
              <a href="http://www.youtube.com/@samsamillustration2991">
                <IconButton> <YoutubeIcon /> </IconButton>
              </a>
              <a target='blank' href="https://martinezsamantha.gumroad.com/">
                <IconButton> <LinkedIcon /> </IconButton>
              </a>
              */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default footer;
