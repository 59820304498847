import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import { Box } from '@mui/material';
import { Masonry } from '@mui/lab';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createGlobalStyle } from 'styled-components';

import "../assets/styles/concept.scss";

AOS.init();
const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

export default function ConceptGallery() {

  const backgrounds = [
    {
      key: 0,
      desc: "Callejon",
      fileName: "./img/backgrounds/04_VISDEV_SAMSAM_01.jpg",
    },
    {
      key: 1,
      desc: "Patineto",
      fileName: "./img/backgrounds/01_VISDEV_SAMSAM_01.jpg",
    },
    {
      key: 2,
      desc: "Baño",
      fileName: "./img/backgrounds/VISDEV_SAMSAM_05.jpg",
    },
    {
      key: 3,
      desc: "Closed Shops",
      fileName: "./img/backgrounds/VISDEV_SAMSAM_04.jpg",
    },
    {
      key: 4,
      desc: "Gatito",
      fileName: "./img/backgrounds/07_VISDEV_SAMSAM_01.jpg",
    },
    {
      key: 5,
      desc: "camion de helados",
      fileName: "./img/backgrounds/VISDEV_SAMSAM_02.jpg",
    },
    {
      key: 6,
      desc: "bureau of lost things",
      fileName: "./img/backgrounds/BDCP_CONCEPT_01.jpg",
    },
    {
      key: 7,
      desc: "miscelanous objects",
      fileName: "./img/backgrounds/VISDEV_SAMSAM_01.jpg",
    }
  ];

  const charDesign = [
    {
      key: 0,
      desc: "Cops",
      fileName: "./img/charDesign/01_CONCEPT_SAMSAM_V01.jpg",
    },
    {
      key: 1,
      desc: "Smoker",
      fileName: "./img/charDesign/03_CONCEPT_SAMSAM_V01.jpg",
    },
    {
      key: 2,
      desc: "Boxer",
      fileName: "./img/charDesign/04_CONCEPT_SAMSAM_V01.jpg",
    },
    {
      key: 3,
      desc: "Fighter 01",
      fileName: "./img/charDesign/FIGHTER_01_SAMSAM_CONCEPT_01.jpg",
    },
    {
      key: 4,
      desc: "Fighter 02",
      fileName: "./img/charDesign/FIGHTER_02_SAMSAM_CONCEPT_01.jpg",
    },
    {
      key: 5,
      desc: "Fighter 03",
      fileName: "./img/charDesign/FIGHTER_03_SAMSAM_CONCEPT_01.jpg",
    },
    {
      key: 6,
      desc: "Fighter 04",
      fileName: "./img/charDesign/FIGHTER_04_SAMSAM_CONCEPT_01.jpg",
    },
    {
      key: 7,
      desc: "Fighter 05",
      fileName: "./img/charDesign/FIGHTER_05_SAMSAM_CONCEPT_01.jpg",
    },
    {
      key: 8,
      desc: "Fighter 06",
      fileName: "./img/charDesign/FIGHTER_06_SAMSAM_CONCEPT_01.jpg",
    }
  ];

  // this prevents right clicks
  useEffect(() => {
    function handleContextMenu(e){
      e.preventDefault();
    }
    const rootElement = document.getElementById('galeries');
    rootElement.addEventListener('contextmenu', handleContextMenu);

    return() => {
      rootElement.addEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  const pageSizeSm = useMediaQuery('(min-width:500px)');
  const pageSizeMd = useMediaQuery('(min-width:780px)');
  const[galerie, setGalerie] = useState('backgrounds');
  // const backgroundsGallery = document.getElementById("galeries");
  const showBackgrounds = () => {
    setGalerie('backgrounds');
    // backgroundsGallery.classList.remove("charDesign");
    const backgroundButton = document.getElementById("backgroundButton");
    const charDesignButton = document.getElementById("charDesignButton");
    backgroundButton.classList.add("focused");
    charDesignButton.classList.remove("focused");
  }
  const showChars = () => {
    setGalerie('chars');
    // backgroundsGallery.classList.add("charDesign");
    const backgroundButton = document.getElementById("backgroundButton");
    const charDesignButton = document.getElementById("charDesignButton");
    backgroundButton.classList.remove("focused");
    charDesignButton.classList.add("focused");
  }

  return(
    <Box className="concept" >
      <GlobalStyles/>
      <div className="selector-buttons">
        <button id='backgroundButton' onClick={showBackgrounds} className="focused"> Background design and props </button>
        <button id='charDesignButton' onClick={showChars}> Character design </button>
      </div>

      <div id="galeries" className="galeries">
        { galerie === 'backgrounds'?
          <Masonry id='backgrounds' columns={1} spacing={1} >
            {backgrounds.map( illus => (
              <div key={illus.key} className="img-container">
                <img src={illus.fileName} alt="gallery" />
              </div>
            ))}
          </Masonry>
          :
          <Masonry id="charDesign" columns={pageSizeSm? pageSizeMd? 3 : 2 : 1} spacing={pageSizeSm? 2 : 1} >
            {charDesign.map( illus => (
              <div key={illus.key} className="img-container">
                <img src={illus.fileName} alt="gallery" />
              </div>
            ))}
          </Masonry>
        }
      </div>
    </Box>
  );
}
