import React from 'react';
import Typed from 'react-typed';
import AOS from 'aos';
import { Box } from '@mui/material';
// import { useMediaQuery, Box } from '@mui/material';
// import "react-circular-progressbar/dist/styles.css";
import '../assets/styles/about.scss';

import samPortrait from '../assets/img/Sam/sam-small-photo.png';
import wipPhoto from '../assets/img/Sam/sam_wip_foto.jpg';

AOS.init();

export default function AboutMe() {

  // <img id='hello' src={helloBanner} alt='Hello' />

  return(
    <Box className='about-container'>
      <div className='about-portrait'>
        <img src={samPortrait} className='cutie-portrait' alt='Cutie Portrait' />
        <div className='typed-title'>
          <Typed
            className="anim-title"
            strings={["I am a Freelancer", "I am an Animator" , "I am an Illustrator"]}
            typeSpeed={60}
            backSpeed={5}
            loop
            />
        </div>
      </div>
      <div className='about-desc'>
        <p>
          Hello! My name is Sam, I'm a digital illustrator and animator based in Mexico, and I am a fantasy and cartoons enthusiast.
          I have a bachelor's degree in animation for video games, but when I finished my degree I realized that character design and illustration it's what I'm really passionate about.
          I've been working for more than 6 years professionally as an illustrator and I hope to continue creating fantastic characters in their own fantasting worlds in the future.
          My experience revolves around creating illustrations for children's books, creation of multimedia content for video marketing agencies and I also focus on my personal work which you can find at my social media.
        </p>
      </div>
      <img src={wipPhoto} className='wip-photo' alt='wipPhoto' />
    </Box>
  );

  // const pageSizeMd = useMediaQuery('(min-width:990px)');
	// return(
	// 	<Box>
	// 	{ pageSizeMd ?
	// 		(
	// 			<Box className='about-container'>
  // 				<div className='about-portrait'>
  //   				<img src={samPortrait} alt='Cutie Portrait' />
	// 				</div>
  // 	 			<div className='about-desc'>
	//     			<img src={helloBanner} alt='Hello' />
  //           <br />
  //           <Typed
  //             className="anim-title"
  //             strings={["I am an Animator", "I am a Freelancer", "I am an Illustrator"]}
  //             typeSpeed={60}
  //             backSpeed={5}
  //             loop
  //             />
  //           <p>
  //             My name is Sam, Im a digital illustrator and animator based in Mexico, and I am a fantasy and cartoons enthusiast.
  //             <br />
  //             I have a bachelor's degree in animation for videogames, but when i finished my degree i realized that character design and illustration it's what I'm really passionate about.
  //             <br />
  //             I have been working for 6 years professionally as an illustrator and I hope to continue creating fantastic characters in their own fantastic worlds in the future.
  //             <br />
  //             My experience revolves around creating illustrations for children's books, creation of multimedia content for video marketing agencies and I also focus on my personal work which you can find at my social media.
  //           </p>
  // 				</div>
	// 			</Box>
	// 		)
	// 		:
	// 		(
	// 			<Box className='about-container'>
  // 				<div className='about-portrait'>
  //           <img id='hello' src={helloBanner} alt='Hello' />
  //           <div className='typed-title'>
  //             <span> I am </span>
  //             <Typed
  //               className="anim-title"
  //               strings={["a Freelancer", "an Animator" , "an Illustrator"]}
  //               typeSpeed={60}
  //               backSpeed={5}
  //               loop
  //               />
  //           </div>
  //   				<img src={samPortrait} alt='Cutie Portrait' />
	// 				</div>
  // 	 			<div className='about-desc'>
  //           <p>
  //             My name is Sam, Im a digital illustrator and animator based in Mexico, and I am a fantasy and cartoons enthusiast.
  //             <br />
  //             I have a bachelor's degree in animation for videogames, but when i finished my degree i realized that character design and illustration it's what I'm really passionate about.
  //             <br />
  //             I have been working for 6 years professionally as an illustrator and I hope to continue creating fantastic characters in their own fantastic worlds in the future.
  //             <br />
  //             My experience revolves around creating illustrations for children's books, creation of multimedia content for video marketing agencies and I also focus on my personal work which you can find at my social media.
  //           </p>
  // 				</div>
	// 			</Box>
	// 		)
	// 	}
	// 	</Box>
  // );
}
