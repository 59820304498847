import Navbar from '../layout/Navbar';
import { useEffect, useRef } from "react";
import "../assets/styles/NavBar.scss";


export default function HeaderBanner(){
  const banner = "./img/banner/ICONO_TITULO.png";
  const bannerRef = useRef(null);

  useEffect(() => {
    const header = document.getElementById("header-wrap");
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > bannerRef.current.height) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, [bannerRef]);

  return(
    <header className="banner">
      <img ref={bannerRef} id="BannerImg" src={banner} alt="BannerSamSam" className="banner-img"/>
      <div className="navbarHolder">
        <div id="header-wrap">
          <Navbar/>
        </div>
      </div>
    </header>
  )
}
