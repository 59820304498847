import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import { Box, Modal, IconButton, useMediaQuery } from '@mui/material';
import { Masonry } from '@mui/lab';
import { createGlobalStyle } from 'styled-components';

import "../assets/styles/illustration-gallery.scss";

import GalleryClose from '../assets/img/buttons/gallery-close.png';
import GalleryNext from '../assets/img/buttons/gallery-next.png';
import GalleryPrev from '../assets/img/buttons/gallery-prev.png';

AOS.init();
const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

export default function Gallery() {
  const pageSizeLg = useMediaQuery('(min-width:900px)');
  const pageSizeMd = useMediaQuery('(min-width:680px)');
  const[open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const openModal = (index) => {
    setIllusIndex(index);
    setOpen(true);
  }

  const[illusIndex, setIllusIndex] = useState(0);
  const nextIllustration = () => {
    console.log(illusIndex);
    if(illusIndex+1 < illusNames.length){
      setIllusIndex(illusIndex+1);
    }
  }
  const prevIllustration = () => {
    console.log(illusIndex);
    if(illusIndex > 0){
      setIllusIndex(illusIndex-1);
    }
    // setIllusIndex(0);
  }

  useEffect(() => {
    function handleContextMenu(e){
      e.preventDefault();
    }
    const rootElement = document.getElementById('illustration');
    rootElement.addEventListener('contextmenu', handleContextMenu);

    return() => {
      rootElement.addEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  const illusNames = [
    {
      key: 0,
      desc: "OC Mechanic",
      fileName: "./img/illus/ILLUS_SAMSAM_01.jpg",
    },
    {
      key: 1,
      desc: "OC Wizard",
      fileName: "./img/illus/ILLUS_SAMSAM_02.jpg",
    },
    {
      key: 2,
      desc: "OC Vampire Witch",
      fileName: "./img/illus/ILLUS_SAMSAM_03.jpg",
    },
    {
      key: 3,
      desc: "Full Illustration",
      fileName: "./img/illus/ILLUS_SAMSAM_04.jpg",
    },
    {
      key: 4,
      desc: "OC Robo-Waitress",
      fileName: "./img/illus/ILLUS_SAMSAM_05.jpg",
    },
    {
      key: 5,
      desc: "OCs Friends",
      fileName: "./img/illus/ILLUS_SAMSAM_06.jpg",
    },
    {
      key: 6,
      desc: "OC Sentai",
      fileName: "./img/illus/ILLUS_SAMSAM_07.jpg",
    },
    {
      key: 7,
      desc: "OC Sentai",
      fileName: "./img/illus/ILLUS_SAMSAM_08.jpg",
    },
    {
      key: 8,
      desc: "OC Vampire SchoolGirl",
      fileName: "./img/illus/ILLUS_SAMSAM_09.jpg",
    },
    {
      key: 9,
      desc: "OC Samurai",
      fileName: "./img/illus/ILLUS_SAMSAM_10.jpg",
    },
    {
      key: 10,
      desc: "OC Explorer",
      fileName: "./img/illus/ILLUS_SAMSAM_11.jpg",
    },
    {
      key: 11,
      desc: "OC Elf",
      fileName: "./img/illus/ILLUS_SAMSAM_12.jpg",
    },
    {
      key: 12,
      desc: "OC Fighter",
      fileName: "./img/illus/ILLUS_SAMSAM_13.jpg",
    },
    {
      key: 13,
      desc: "Samurai Jack",
      fileName: "./img/illus/ILLUS_SAMSAM_14.jpg",
    },
    {
      key: 14,
      desc: "Baseball Character",
      fileName: "./img/illus/BASEBALL_CHARACTER_SAMSAM_V02.jpg",
    },
  ];

  return(
    <Box id="gallery" className="gallery" >
      <GlobalStyles/>
      <Masonry id="illustration" columns={pageSizeMd? (pageSizeLg? 3 : 2) : 1} spacing={2} >
      {illusNames.map( illus => (
          <div key={illus.key} className="img-container"  >
            <img src={illus.fileName} alt="gallery" onClick={() => openModal(illus.key)}/>
          </div>
      ))}
      </Masonry>

      <Modal open={open} onClose={closeModal}>
        {pageSizeMd?
          (
            <Box className="big-gallery-modal">
              <IconButton
                id="close-btn"
                className="icon-button-modal"
                onClick={closeModal}
                >
                <img src={GalleryClose} alt='close' />
              </IconButton>
              <IconButton
                id="prev-btn"
                className="icon-button-modal"
                onClick={prevIllustration}>
                <img src={GalleryPrev} alt='prev' />
              </IconButton>
              <img src={illusNames[illusIndex].fileName} alt={illusNames[illusIndex].desc}/>
              <IconButton
                id="next-btn"
                className="icon-button-modal"
                onClick={nextIllustration}>
                <img src={GalleryNext} alt='next' />
              </IconButton>
            </Box>
          )
          :
          (
            <Box className="big-gallery-mobile">
              <IconButton
                id="close-btn"
                className="icon-button-modal"
                onClick={closeModal}
                >
                <img src={GalleryClose} alt='close' />
              </IconButton>
              <img id="illustration" src={illusNames[illusIndex].fileName} alt={illusNames[illusIndex].desc}/>
              <div className="gallery-buttons">
                <IconButton
                  id="prev-btn"
                  className="icon-button-modal"
                  onClick={prevIllustration}>
                  <img src={GalleryPrev} alt='prev' />
                </IconButton>
                <IconButton
                  id="next-btn"
                  className="icon-button-modal"
                  onClick={nextIllustration}>
                  <img src={GalleryNext} alt='next' />
                </IconButton>
              </div>
            </Box>
          )
        }
      </Modal>

    </Box>
  );
}
