import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom"
import { createGlobalStyle } from 'styled-components';
//import { NavLink, Link as LinkRouter} from "react-router-dom"

import '../assets/styles/NavBar.scss'

import menuButton from '../assets/img/06_MENU_small.png'

const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit{
      display: block ;
    }
    .navbar-brand .imgsaly{
      display: none !important;
    }
`;

export default function Navbar() {

  var illusON = "./img/navbar-icons/01_ILLUSTRATIONS_ON.png"
  var illusOFF = "./img/navbar-icons/01_ILLUSTRATIONS_OFF.png"
  var bookON = "./img/navbar-icons/02_BOOKS_ON.png"
  var bookOFF = "./img/navbar-icons/02_BOOKS_OFF.png"
  var conceptON = "./img/navbar-icons/03_CONCEPT_ON.png"
  var conceptOFF = "./img/navbar-icons/03_CONCEPT_OFF.png"
  var aboutON = "./img/navbar-icons/04_ABOUT_ON.png"
  var aboutOFF = "./img/navbar-icons/04_ABOUT_OFF.png"
  var contactON = "./img/navbar-icons/05_CONTACT_ON.png"
  var contactOFF = "./img/navbar-icons/05_CONTACT_OFF.png"

  const [showmenu, setShowmenu] = useState(false);
  useEffect(() => {
    const scrollCallBack = window.addEventListener("scroll", () => {
      setShowmenu(false);
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

function PageLink(props){

  const currentLink = window.location.href;
  const currentAddress = currentLink.split('/');

  if(currentAddress[3] === props.link){
    return(
      <Link className="icon active" spy to={props.link}>
        <img src={props.logoON} alt={props.altName}/>
        <p> {props.link} </p>
      </Link>
    )
  } else{
    return(
      <NavLink className="icon" to={"/" + props.link}>
        <img src={props.logoOFF} alt={props.altName}/>
        <p> {props.link} </p>
      </NavLink>
    )
  }
}

  return(
    <nav className="navbar">
      <GlobalStyles/>
      <div className="navbarContainer">
        {/* Desktop menu Here */}
        <div className="desktopmenu">
          <ul className="navbar-nav">
            <li className="nav-item">
              <PageLink link="Illustrations" logoON={illusON} logoOFF={illusOFF} altName="Potion Icon"/>
            </li>
            <li className="nav-item">
              <PageLink link="Books" logoON={bookON} logoOFF={bookOFF} altName="Book Icon"/>
            </li>
            <li className="nav-item">
              <PageLink link="Concept" logoON={conceptON} logoOFF={conceptOFF} altName="Inkwell Icon"/>
            </li>
            <li className="nav-item">
              <PageLink link="About" logoON={aboutON} logoOFF={aboutOFF} altName="Candle Icon"/>
            </li>
            <li className="nav-item">
              <PageLink link="Contact" logoON={contactON} logoOFF={contactOFF} altName="Letter Icon"/>
            </li>
          </ul>
        </div>
        {/* Desktop menu Here */}

        {/* mobile menu here */}
        <button className="burgermenu" type="button" onClick={() => setShowmenu(!showmenu)}>
          <img src={menuButton} alt="menu-button" />
        </button>
        {showmenu &&
          <div className="mobilemenu" >

            <ul className="navbar-nav navbar-mobile">
               <li className="nav-item">
                 <PageLink link="Illustrations" logoON={illusON} logoOFF={illusOFF} altName="Potion Icon"/>
              </li>
              <li className="nav-item">
                <PageLink link="Books" logoON={bookON} logoOFF={bookOFF} altName="Book Icon"/>
              </li>
              <li className="nav-item">
                <PageLink link="Concept" logoON={conceptON} logoOFF={conceptOFF} altName="Inkwell Icon"/>
              </li>
              <li className="nav-item">
                <PageLink link="About" logoON={aboutON} logoOFF={aboutOFF} altName="Candle Icon"/>
              </li>
              <li className="nav-item">
                <PageLink link="Contact" logoON={contactON} logoOFF={contactOFF} altName="Letter Icon"/>
              </li>
            </ul>

          </div>
        }
        {/* mobile menu here */}
      </div>
    </nav>
  )
}
