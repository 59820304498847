import Gallery from '../section/gallery';
import Footer from '../section/footer';
import Preloader from "../layout/Preloader";
import HeaderBanner from '../section/headerBanner';


import '../assets/styles/home.scss';
import { createGlobalStyle } from 'styled-components';
const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit{
      display: block ;
    }
    .navbar-brand .imgsaly{
      display: none !important;
    }
`;

export default function Illustration() {

  return (
    <div>
      <GlobalStyles />
      <Preloader/>
      <div className="home">

        <HeaderBanner />

        {/* Gallery */}
        <section id="Ilustraciones" className="pb-0">
          <Gallery/>
        </section>

        {/* contact */}
        <section id="Footer" className="pb-0">
          <Footer/>
        </section>

      </div>
    </div>
  );
}
